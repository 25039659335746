<template>
  <div
    class="container"
  >
    <div class="flex">
      <Question 
        :size="1.5"
      />
      <h2 v-if="typeId === constant.SETTINGS_MAX_COIN_PRICE_MULTIPLIER_ID">Max Coin Price Setting</h2>
      <h2 v-if="typeId === constant.SETTINGS_MIN_COIN_PRICE_MULTIPLIER_ID">Min Coin Price Setting</h2>
      <h2 v-if="typeId === constant.SETTINGS_MINT_MAX_SUPPLY_RATE_ID">Max Mint Supply Rate Setting</h2>
      <h2 v-if="typeId === constant.SETTINGS_MINT_COLLATERAL_RATE_ID">Network Collateral Rate Setting</h2>
    </div>
    <div>
      <p v-if="typeId === constant.SETTINGS_MAX_COIN_PRICE_MULTIPLIER_ID">The default Max Price setting is set to 20 times the market price for any FAsset. The allowed maximum price for any FAsset is its current market price times the max&nbsp;price&nbsp;multiplier.</p>
        <p v-if="typeId === constant.SETTINGS_MAX_COIN_PRICE_MULTIPLIER_ID">You can predict the potential future price of any FAsset within SimFasset on the Mint Fassets page by selecting the <PriceSliderIcon :color="constant.BLACK"/> icon within the details section.</p>
       
      <p v-if="typeId === constant.SETTINGS_MIN_COIN_PRICE_MULTIPLIER_ID">The default Min Price setting is set to 1/10th (.10) the market price for any FAsset. The allowed minimum price for any FAsset is its current market price times the min&nbsp;price&nbsp;multiplier.</p>
        <p v-if="typeId === constant.SETTINGS_MIN_COIN_PRICE_MULTIPLIER_ID">You can predict the potential future price of any FAsset within SimFasset on the Mint Fassets page by selecting the <PriceSliderIcon :color="constant.BLACK"/> icon within the details section.</p>
        
      <p v-if="typeId === constant.SETTINGS_MINT_MAX_SUPPLY_RATE_ID">SimFasset currently limits how many FAsset coins can be minted onto the {{ activeNetwork.name }}. The default Max Mint setting is set to 5% for any FAssets circulating&nbsp;supply but can be adjusted&nbsp;accordingly.</p>
      
      <p v-if="typeId === constant.SETTINGS_MINT_COLLATERAL_RATE_ID">The minimum network collateral rate has yet to be finalized by the Flare Team. SimFasset currently defaults the minimum collateral rate setting to 150% but can be adjusted&nbsp;accordingly.</p>
    </div>
  </div>
</template>
<script setup>
  import Question from '../_icons/Question.vue'
  import PriceSliderIcon from '../_icons/PriceSimulatorIcon.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    typeId: {
      type: Number,
      default: 0
    }
  })

  const constant = props.constant

</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';
  
  .container {
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: column;
    
    .flex {
      width: 100%;
      margin-top: 10px;
      justify-content: center;

      h2 {
        color: $BLACK;
        font-weight: 600;
        margin:  0 0 0 10px;
      }
    }
    p {
      margin: 20px 10px;
      font-size: $FONT-SMALL;
      color: $BLACK;
    }
  }
</style>
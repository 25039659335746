<template>
  <svg 
    :width="sWidth" 
    :height="sHeight"
    :viewBox="viewBox"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11.75" cy="11" r="10.25" stroke="#000" stroke-width="1.5"/>
    <path d="M13.2413 9.08142c0-.812-.574-1.288-1.568-1.288-.854 0-1.69397.434-2.21197 1.218l-1.428-1.176c.98-1.162 2.11397-1.82 3.77997-1.82 1.834 0 3.57 1.134 3.57 3.066 0 1.70798-1.512 2.75798-2.996 3.00998l-.07 1.176h-1.708l-.28-2.492h.448c1.68 0 2.464-.97998 2.464-1.69398Zm-3.094 6.17398c0-.616.56-1.162 1.33-1.162.756 0 1.316.546 1.316 1.162 0 .63-.56 1.162-1.316 1.162-.77 0-1.33-.532-1.33-1.162Z" fill="#000"/>
  </svg>
</template>
<script setup>
  import { ref } from 'vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 23
    },
    height: {
      type: Number,
      default: 22
    },
    size: {
      type: Number,
      default: 1.0
    },
  })
  const constant = props.constant
  const viewBox = `0 0 ${props.width} ${props.height}`

  const sWidth = ref(0)
  sWidth.value = Math.round(props.width * props.size)

  const sHeight = ref(0)
  sHeight.value = Math.round(props.height * props.size)
</script>
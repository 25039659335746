<template>
  <InputRange
    v-if="inputType === 'static'"
    :constant="constant"
    :disabled="true"
    :classes="classes"
    :styles="staticRangeStyles"
    :modelValue="staticValue"
  />
  <InputRange
    v-else
    :constant="constant"
    :disabled="collateralRequired === 0 ? true : false"
    :classes="classes"
    :styles="interactiveRangeStyles"
    :modelValue="modelValue"
    @sliderValue="sliderValueUpdate($event)"
  /> 
</template>
<script setup>
  import { ref, reactive, computed, watch } from 'vue'
  import InputRange from '../_generics/InputRange.vue'
  
  const props = defineProps({
    id: {
      type: Number,
      default: 0
    },
    constant: {
      type: Object,
      default: () => {}
    },
    inputType: {
      type: String,
      default: 'interactive'
    },
    modelType: {
      type: String,
      default: 'interactive'
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    totalCollateralRequired: {
      type: Number,
      default: 0
    },
    sliderFillOpacity: {
      type: Number,
      default: 1
    },
    sliderEmptyOpacity: {
      type: Number,
      default: 1
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const emit = defineEmits([
    'updateChartSettings',
    'updateModifiedNetworkPrice',
    'updateNetworkCollateralPercentage',
  ])

  const constant = props.constant

  const initialized = ref(false)
  const sliderBgColor = ref('')

  const staticValue = ref(0)
  const modelValue = ref(0)

  const previousPrice = ref(0)
  let priceRangeTable = []

  let drawChart = ''

  const inputRangeActive = computed(() => {
    return props.activeNetwork.name === constant.SONGBIRD_NETWORK && collateralRequired.value > 0
  })

  const classes = computed(() => {
    if(props.inputType === 'static') {
      return { 
        'slider' : true, 
        'static-slider' : true
      }
    }
    return {
      'slider' : true,
      'slider-interactive': true,
      'border-color-red': inputRangeActive.value
    }
  })

  const interactiveRangeStyles = computed(() => {
    const styles = {'background' : `linear-gradient(90deg, rgba(${ sliderBgColor.value }, ${props.sliderFillOpacity}) ${modelValue.value}%, rgba(${ constant.WHITE_RGB }, ${props.sliderEmptyOpacity}) ${modelValue.value}%)`}
    return styles
  })

  const staticRangeStyles = computed(() => {
    const styles = { 'background' : `linear-gradient(90deg, rgba(${ sliderBgColor.value }, ${props.sliderFillOpacity}) ${staticValue.value}%, rgba(${ constant.WHITE_RGB }, ${props.sliderEmptyOpacity}) ${staticValue.value}%)` }
    return styles
  })

  const coinPrice = computed(() => {
    const price = props.activeNetwork.modifiedPrice === 0 ? props.activeNetwork.price : props.activeNetwork.modifiedPrice
    return price
  })

  const tradableSupply = computed(() => {
    return props.activeNetwork.circulatingSupply - props.configs.topHoldersTotalCoinExclusion
  })

  watch(() => [props.configs.topHoldersTotalCoinExclusion, props.totalCollateralRequired, props.activeNetwork.name], (newValue, oldValue) => {
    initializePriceControllers()

    if(props.inputType === 'interactive') {
      initialized.value = false
    }

    //Static Range Input - Gradiant Updated
    if(props.modelType === 'static') {
      if(newValue[2] != oldValue[2]) {
        sliderBgColor.value = props.activeNetwork.name === constant.FLARE_NETWORK ? constant.PURPLE_RGB : constant.RED_RGB
      }
    }
  })

  const minPrice = computed(() => {
    const mPrice = collateralRequired.value / tradableSupply.value
    return mPrice
  })

  const maxPrice = computed(() => {
    const mPrice = minPrice.value * getConfigsSettingById(constant.SETTINGS_NETWORK_MAX_PRICE_MULTIPLIER_ID).set
    return mPrice
  })

  const formula = computed(() => {
    const result = (maxPrice.value - (0.01425 * maxPrice.value)) * 0.01425 * 0.01425
    return result
  })

  const collateralPercentage = computed(() => {
  let percentage = numFormatter((collateralRequired.value / tradableSupply.value / coinPrice.value) * 100, 1)

    if(percentage >= 100) {
      percentage = 100
    }
    return percentage
  })

  const collateralRequired = computed(() => {
    if(props.collateralFAssetIsActive) {
     return props.totalCollateralRequired * props.networkCollateralAllocation[2].set
    } 
    return props.totalCollateralRequired * props.networkCollateralAllocation[1].set
  })

  const getConfigsSettingById = (settingId) => {
    const setting = props.configs.settings.find((setting) => setting.id === settingId)
    return setting
  }

  const sliderValueUpdate = (event) => {
    modelValue.value = parseInt(event)

    if(initialized.value) {
      updateModifiedPrice(priceRangeTable[modelValue.value].price)
      updateCollateralPercentage()
      updateChartSettings()
    }
    initialized.value = true
  }

  //Model Updates
  const updateModifiedPrice = (price) => {
    let modifiedPriceEvent = {
      id: props.activeNetwork.id,
      price: price, 
    }
    emit('updateModifiedNetworkPrice', modifiedPriceEvent)
  }

  const updateCollateralPercentage = () => {
    let collateralDetails = {
      id: props.activeNetwork.id,
      collateralPrecentageRequired: collateralPercentage.value
    }
    emit('updateNetworkCollateralPercentage', collateralDetails)
  }

  const updateChartSettings = () => {
    let chartSettings = {
      id: props.chartData.id,
      src:'SimulatePriceControllerInput',
      data: calculateChartData(),
      rotation: calculateChartRotation(),
      options: {
        animations: {
          duration: 250
        }
      }
    }

    clearTimeout(drawChart)
    emit('updateChartSettings', chartSettings)
  }

  const calculateChartData = () => {
    const data = []
    const result1 = props.activeNetwork.collateralPercentageRequired
    const result2 = 100 - props.activeNetwork.collateralPercentageRequired

    data.push(result1)
    data.push(result2)
    return data
  }

  const calculateChartRotation = () => {
    const result = 100 - (props.activeNetwork.collateralPercentageRequired - 100) * -1
    const percentage = (100 - result)/100
    const rotation = 180 * percentage
    return rotation
  }

  const numFormatter = (value, decimalCount) => {
    return value.toFixed(decimalCount)
  }

  const createPriceRangeTableData = () => {
    priceRangeTable = []
    let prevPrice = 0

    for(let i = 0; i < 101; i++) {
      priceRangeTable.push({
        id: i + 1,
        cal: i * formula.value,
        price: (i * formula.value) + prevPrice + 0.000001
      })
      prevPrice = priceRangeTable[i].price
    }
  }

  const getRangeTick = (price) => {
    const getRangeSet = []
    let priceData = null

    const len = priceRangeTable.length

    for(let i = 0; i < len; i++) {
      let t = priceRangeTable[i]

      if(t.price < price) {   
        getRangeSet.push(t)
      }
    }
    priceData = getRangeSet[getRangeSet.length - 1]
    return priceData
  }

  //Set Initial Range Value - Market Price
  const setInitialStaticRangeInputValue = () => {
    const tick = getRangeTick(props.activeNetwork.price)
    return collateralRequired.value === 0 ? 0 : tick.id
  }

  //Set Initial Range Value - Ineractive Speculative Price + Min Price
  const setInitialInteractiveRangeInputValue = () => {
    let tick = ''
    const price = ref(0)
    
    if(props.inputType === 'interactive') {
      price.value = props.activeNetwork.modifiedPrice === 0 ? minPrice.value : props.activeNetwork.modifiedPrice
      price.value = props.activeNetwork.modifiedPrice > priceRangeTable[priceRangeTable.length - 1].price ? minPrice.value : price.value
      price.value = minPrice.value > price.value ? minPrice.value : price.value
      price.value = price.value === props.activeNetwork.price ? 0 : price.value

      updateModifiedPrice(price.value)
      updateCollateralPercentage()
    } else {
      price.value = minPrice.value > props.activeNetwork.price ? minPrice.value : props.activeNetwork.price
    }

    tick = getRangeTick(price.value)
    return collateralRequired.value === 0 ? 0 : tick.id
  }

  //Initialization
  const initializePriceControllers = () => {
    
    if(props.id === 1) {
      sliderBgColor.value = props.activeNetwork.name === constant.FLARE_NETWORK ? constant.PURPLE_RGB : constant.RED_RGB
    } else {
      sliderBgColor.value = props.activeNetwork.name === constant.FLARE_NETWORK ? constant.PURPLE_CHART_MAX_RGB : constant.RED_CHART_MAX_RGB
    }

    createPriceRangeTableData()

    if(props.modelType === 'static') {
      staticValue.value = setInitialStaticRangeInputValue() //Market Price
    }else {
      staticValue.value = modelValue.value = setInitialInteractiveRangeInputValue()
    }
  }
  
  initializePriceControllers()
</script>
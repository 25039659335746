<template>
  <div class="collateral-slider-container">
    <InputRange 
      :constant="constant"
      :disabled="false"
      :classes="classes"
      :styles="interactiveRangeStyles"
      :modelValue="modelValue"
      @sliderValue="sliderValueUpdate($event)"
    />
    <div class="percentage-range flex">
      <span>0.00%</span>
      <span>100.00%</span>
    </div>
  </div>
</template>
<script setup>
  import {ref, computed } from "vue"
  import InputRange from "./InputRange.vue"

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const emit = defineEmits([
    'updateChartSettings',
    'updateNetworkCollateralAllocation'
  ])

  const constant = props.constant
  const modelValue = ref(50)
  const collateralAllocation = ref(0)

  let collateralRangeTable = []

  const classes = computed(() => {
    return {
      'collateral-slider' : true
    }
  })

  const interactiveRangeStyles = computed(() => {
    return {'background' : `linear-gradient(90deg, rgba(${ constant.PURPLE_XLIGHT_RGB }, 1) ${modelValue.value}%, rgba(${ constant.WHITE_RGB }, 1) ${modelValue.value}%)`}
  })

  const sliderValueUpdate = (event) => {
    modelValue.value = parseInt(event)
    collateralAllocation.value = collateralRangeTable[modelValue.value].range
    calCollateralAllocation(collateralAllocation.value.toFixed(1))
  }

  const calCollateralAllocation = (rangeTick) => {
    const collateralAllocation = []
    collateralAllocation.push(100-parseInt(rangeTick))
    collateralAllocation.push(parseInt(rangeTick))
    
    
    const collateralSettings = {
      id: 3,
      data: collateralAllocation,
      rotation: calRotation(collateralAllocation)
    }
    emit('updateChartSettings', collateralSettings)

    const networkCollateral = {
      id: props.activeNetwork.id,
      data: collateralAllocation,
    }
    emit('updateNetworkCollateralAllocation', networkCollateral)
  }

  const calRotation = (data) => {
    return (((90 / 25) * data[1]) / 2) + 180
  }

  const getRangeTick = (collateral) => {
    const len = collateralRangeTable.length
    let id = 0
    for(let i = 0; i < len; ++i) {
      if(collateralRangeTable[i].range === collateral) {   
        id = i + 1
      }
    }
    return id
  }

  const setInitialRangeInputValue = () => {
    const collateral = parseInt(props.networkCollateralAllocation[1].set * 100)
    const tickId = getRangeTick(collateral)
    return tickId
  }

  const createCollateralTableData = () => {
    collateralRangeTable = []

    for(let i = 0; i < 101; ++i ) {
      collateralRangeTable.push({
        id: i+1,
        range: i
      })
    }
  }

  const initializeCollateralSetter = () => {
    createCollateralTableData()
    modelValue.value = setInitialRangeInputValue()
  }

  initializeCollateralSetter()
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .collateral-slider-container {
    position: relative;
    margin: 10px auto 0 auto;
    overflow-y: hidden;
    min-width: 340px;
    max-width: 355px;
    height: 40px;
    width: 90%;

    @include breakpoint(sm) {
      max-width: 500px;
    }
    @include breakpoint(md) {
      max-width: 650px;
    }
    @include breakpoint(lg) {
      max-width: 850px;
    }
    @include breakpoint(xl) {
      max-width: 850px;
    }
    @include breakpoint(xxl) {
      max-width: 850px;
    }
    @include breakpoint(xxxl) {
      max-width: 850px;
    }

    .collateral-slider {
      margin-top: 15px;
      width: 100%;
    }

    .percentage-range{
      padding-top: 25px;
    }
  }
</style>
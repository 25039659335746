<template>
   <div class="control-set ml-15 flex">
      <!-- <Button
        :isActive="false"
        :eventName="constant.SMS"
        @clicked="shareClicked($event)"
      >
        <SmsShareIcon 
          :isActive="isActiveSms"
        />
      </Button>
      <Button
        :isActive="true"
        :eventName="constant.TWITTER"
        @clicked="shareClicked($event)"
      >
        <TwitterShareIcon 
        />
      </Button> -->
    </div>
</template>
<script setup>
  import { event } from 'vue-gtag'
  import Button from '../../_generics/Button.vue'
  import SmsShareIcon from '../../_icons/Share/SmsShareIcons.vue'
  import TwitterShareIcon from '../../_icons/Share/TwitterShareIcons.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    classes: {
      type: String,
      default: ''
    },
    isActiveSms: {
      type: Boolean,
      default: false
    },
  })

  const constant = props.constant
  const emit = defineEmits([
    'shareApplication',
  ])

  const shareClicked = (event) => {
    if(constant.SMS === event) {
      trackEvent('share_sms', 'copied_sms_link', 'share_sms_click')
    } else {
      trackEvent('share_x', 'post_link_x', 'share_x_click')
    }

    emit('shareApplication', event)
  }

  const trackEvent = (eventType, eventCategory, eventLabel) => {
    event(eventType, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }
</script>
<template>
  <Modal
    v-if="!loading"
    :constant="constant"
    :show="screenManagerStore.getShowModal"
    @hideModal="hideModal($event)"
  >
    <template 
      v-if="modalIsActive"
      #modal-content
    >
      <ModalShareSms
        v-if="isActiveSms"
        :start="isActiveSms"
        :pathName="pathName"
        @hideModal="hideModal($event)"
      />
      <ModalMinCollateral
        v-if="isActiveMinCollateral"
        :constant="constant"
        :activeNetwork="networkStore.getActiveNetwork"
        @nextScreen="nextPrimaryScreen($event)"
      />
      <ModalHelp
        v-if="isActiveHelp"
        :constant="constant"
        :activeNetwork="networkStore.getActiveNetwork"
        :typeId="helpSettingId"
      />
    </template>
  </Modal>

  <AppHeader
    v-if="!loading"
    :constant="constant"
    :creator="configurationStore.getCreator"
    :appVersion="configurationStore.getAppVersion"
    :activeNetwork="networkStore.getActiveNetwork"
    :configs="configurationStore.getSystemConfigsByName(networkStore.getActiveNetwork.name)"
    :settingsScreen="screenManagerStore.getScreenSubNav(constant.SETTINGS)"
    :infoScreen="screenManagerStore.getScreenSubNav(constant.INFORMATION)"
    :informationScreens="screenManagerStore.getScreensInformation"
    :screensCarousel="screenManagerStore.getScreensCarouselHowTo"
    :localStorageAgreed="configurationStore.getLocalStorageCookie.agreed"
    :toggleSettingsButtons="screenManagerStore.getScreensSettingsView(networkStore.getActiveNetwork.id)"
    
    @nextScreen="activateNetwork($event)"
    @showModalHelp="showModalHelp($event)"
    @activateScreenSubNav="activateScreenSubNav($event)"
    @updateScreenSettings="updateScreenSettings($event)"
    @updateScreenInformation="updateScreenInformation($event)"
    @updateConfiguration="updateConfiguration($event)"
    @resetConfiguration="resetConfiguration($event)"
    @resetAllAppData="resetAppData($event)"
  />

  <MintCollateralControlsNav
    :constant="constant"
    :isMinted="coinStore.isMinted"
    :loading="loading"
    :route="route"
    @nextScreen="nextPrimaryScreen($event)"
    @resetMintQuantity="resetMintQuantity($event)"
    @deactivateAccordions="deactivateAccordions($event)"
  />

  <LoadingView v-if="loading" />
  <main 
    v-else
    :class="{'no-scroll' : screenManagerStore.getShowModal}"
  >
    <section class="section">
      <router-view v-slot="{ Component }">
        <Transition name="page">
          <component :is="Component" />
        </Transition>
      </router-view>
    </section>
  </main>

  <AppNav
    v-if="!loading"
    :constant="constant"
    :activeNetwork="networkStore.getActiveNetwork"
    :networkScreen="screenManagerStore.getScreenSubNav(constant.NETWORK)"
    :shareScreen="screenManagerStore.getScreenSubNav(constant.SHARE)"
    :isActiveSimulateBtn="coinStore.isMinted && route.name === constant.MINTING"
    :isActiveSms="isActiveSms"
    :cookies="configurationStore.getLocalStorageCookie"
    :cookieDisclosureScreen="screenManagerStore.getScreenSubNav(constant.COOKIE_DISCLOSURE)"
    @nextScreen="nextPrimaryScreen($event)"
    @deactivateAccordions="deactivateAccordions($event)"
    @activateScreenSubNav="activateScreenSubNav($event)"
    @activateNetwork="activateNetwork($event)"
    @shareApplication="shareApplication($event)"
    @updateConfiguration="updateConfiguration($event)"
  />

</template>
<script setup>
  import { ref, inject, watch } from 'vue'
  import { RouterView, useRouter, useRoute } from 'vue-router'

  import { useNetworkStore } from './stores/NetworkStore.js'
  import { useScreenManagementStore } from './stores/ScreenManagementStore.js'
  import { useConfigurationStore } from './stores/ConfigurationStore.js'
  import { useChartStore } from './stores/ChartStore.js'
  import { useCoinStore } from './stores/CoinStore.js'

  import { event } from "vue-gtag"
  
  import LoadingView from './views/LoadingView.vue'

  import AppHeader from './components/App/AppHeader/AppHeader.vue'
  import AppNav from './components/App/AppNavigation/AppNav.vue'
  import MintCollateralControlsNav from './components/Mint/MintCollateralControlsNav.vue'
 
  import Modal from './components/_generics/Modal.vue'
  import ModalHelp from './components/_modals/ModalHelp.vue'
  import ModalShareSms from './components/_modals/ModalShareSms.vue'
  import ModalMinCollateral from './components/_modals/ModalMinCollateral.vue'

  const constant = inject('constant')
  const loading = ref(true)
  const isActiveSms = ref(false)
  const isActiveMinCollateral = ref(false)
  const isActiveHelp = ref(false)
  const helpSettingId = ref(0)
  const modalIsActive = ref(false)
  const pathName = ref('')
  const coinMinted = ref(0)
  const activeNetworkName = ref(constant.SONGBIRD_NETWORK)
  let delay = null

  const networkStore = useNetworkStore()
  const screenManagerStore = useScreenManagementStore()
  const configurationStore = useConfigurationStore()
  const chartStore = useChartStore()
  const coinStore = useCoinStore()
  const coins = coinStore.getCoins

  const router = useRouter()
  const route = useRoute()

  networkStore.setActiveNetwork(constant.SONGBIRD_NETWORK)
  networkStore.fetchAllNetworkData()
  networkStore.setNetworksDefaults()
  screenManagerStore.createAccordionScreens(coins)
  configurationStore.setConfigsDefaults(networkStore.getActiveNetwork.name)
  coinStore.setConfigurationSettings(configurationStore.getSystemConfigsByName(networkStore.getActiveNetwork.name))
  coinStore.fetchCoinData()
  

  const loadApp = () => {
    loading.value = false

    //SHARED LINK DATA - LOAD
    if(Object.keys(route.query).length) {
      setSharedApplication(route.query)
      clearTimeout(loadTimer)
      return
    }

    //PERSISTED DATA - LOAD
    if(configurationStore.getLocalStorageCookie.agreed) {
      loadPersistedAppData()
    }
    clearTimeout(loadTimer)
  }

  const appReset = () => {
    networkStore.setActiveNetwork(activeNetworkName.value)
    resetApplication()
    configurationStore.setConfigsDefaults(networkStore.getActiveNetwork.name)
    coinStore.setConfigurationSettings(configurationStore.getSystemConfigsByName(networkStore.getActiveNetwork.name))
    clearTimeout(delay)
  }
  const resetApplication = (event = null) => {
    deactivateAccordions()
    coinStore.resetMintedCoins()
    coinStore.resetModifiedCoinPrices()
    screenManagerStore.deactivateScreensSubNav()
    screenManagerStore.setDefaultActiveSettingsScreen(networkStore.getActiveNetwork.id)
    screenManagerStore.setDefaultActiveMintCollateralSceen()
    networkStore.setNetworksDefaults()
    networkStore.resetModifiedCoinPrice(networkStore.getActiveNetwork.name)
    coinStore.setConfigurationSettings(configurationStore.getSystemConfigsByName(networkStore.getActiveNetwork.name))
  }

  const resetAppData = (event) => {
    screenManagerStore.deactivateScreensSubNav()
    configurationStore.deleteAllLocalStorage()
    configurationStore.resetAllConfigurationsToDefault()
    router.push({path : '/'})
    resetApplication()
    configurationStore.setLocalStorageCookiesDefault()
    screenManagerStore.setActiveSubNavScreen(constant.COOKIE_DISCLOSURE)
  }

  const switchNetworks = () => {
    networkStore.setActiveNetwork(activeNetworkName.value)
    configurationStore.setLocalStorage(constant.LOCAL_STORAGE_NETWORK_KEY, networkStore.getActiveNetwork.id)
    resetApplication()
    loadPersistedAppData()
    trackEvent(`network_change_to_${networkStore.getActiveNetwork.ticker.toLowerCase()}`, 'network_change_to', activeNetworkName.value)
    clearTimeout(delay)
  }

  //NETWORK MANAGEMENT 
  const activateNetwork = (event) => {
    if(event === constant.GET_STARTED) {
      screenManagerStore.deactivateScreensAccordions()
      screenManagerStore.deactivateScreensSubNav()
      router.push({path : '/'})
      return
    }

    activeNetworkName.value = event

    //PERSISTED DATA - LOAD
    if(configurationStore.getLocalStorageCookie.agreed) {

      //Check for Coins - If they exist then route user to Simulate
      const index = networkStore.getActiveNetwork.id === 2 ? 0 : 1
      const qpData = configurationStore.getLocalStorage(constant.LOCAL_STORAGE_KEY)

      if(qpData[index].val) {
        const appData = configurationStore.getSerializedPresistedAppData(qpData[index].val)

        if(coinStore.isQueryMinted(appData)) {
          delay = setTimeout(switchNetworks, 100)
          return
        }
      }
      
      router.push({path : '/'})
      delay = setTimeout(switchNetworks, 100)
      return
    }

    //NO LocalStorage - not user accepted
    router.push({path : '/'})
    delay = setTimeout(appReset, 100)
  }

  //SCREEN MANAGEMENT
  const deactivateAccordions = (event = null) => {
    screenManagerStore.deactivateScreensAccordions()
  }
  const updateScreenSettings = (event) => {
    screenManagerStore.setActiveSettingsScreen(event)
  }
  const updateScreenInformation = (event) => {
    screenManagerStore.setActiveInformationScreen(event)
  }
  const nextPrimaryScreen = (event) => {
    screenManagerStore.deactivateScreensSubNav()

    if(event === constant.GET_STARTED)
      return router.push({path : `/`})
    router.push({path : `/${event}`})
  }
  const activateScreenSubNav = (event) => {
    screenManagerStore.setActiveSubNavScreen(event)
  }
  const resetMintQuantity = (event) => {
    coinStore.resetMintedCoins()
    networkStore.resetModifiedCoinPrice(networkStore.getActiveNetwork.name)
    configurationStore.setLocalStorage(constant.LOCAL_STORAGE_NETWORK_KEY, networkStore.getActiveNetwork.id)
    configurationStore.setPresistedConfigsData(getPersistedAppData())
    screenManagerStore.deactivateScreensAccordions()
  }

  //CONFIGURATION MANAGEMENT
  const updateConfiguration = (event) => {

    if(event.type === constant.SETTINGS_CONFIGURATIONS && event.set === null) {
      networkStore.resetModifiedCoinPrice(networkStore.getActiveNetwork.name)
    }

    configurationStore.updateConfigurationItem(event)
    coinStore.calTotalCollateral()
    configurationStore.setLocalStorage(constant.LOCAL_STORAGE_NETWORK_KEY, networkStore.getActiveNetwork.id)
    configurationStore.setPresistedConfigsData(getPersistedAppData())
  }
  const resetConfiguration = (event) => {
    configurationStore.resetConfiguration(event)
    configurationStore.setLocalStorage(constant.LOCAL_STORAGE_NETWORK_KEY, networkStore.getActiveNetwork.id)
    configurationStore.setPresistedConfigsData(getPersistedAppData())
  }

  //SHARED APPLICATION
  const setSharedApplication = (qParams) => {
    networkStore.setNetworksDefaults()
    networkStore.setQueryNetworkData(qParams)
    activeNetworkName.value = networkStore.getActiveNetwork.name
    configurationStore.setConfigsDefaults(activeNetworkName.value)
    configurationStore.setQueryConfigsData(qParams, activeNetworkName.value)
    coinStore.setConfigurationSettings(configurationStore.getSystemConfigsByName(networkStore.getActiveNetwork.name))
    coinStore.setQueryCoinData(qParams)

    trackEvent(`shared_link_view_${networkStore.getActiveNetwork.ticker.toLowerCase()}`, 'shared_link_view', activeNetworkName.value)
  }

  const currencyFormatter = (value, decimalCount = 2) => {
    return value.toFixed(decimalCount).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }

  const shareApplication = (event) => {
    const url =  createShareURL()

    switch(event) {
      case constant.SMS : shareSms(url)
      break
      case constant.TWITTER : twitter(url)
      break
      default : shareSms(url)
    }
  }
  const shareSms = (url) =>{
    isActiveSms.value = true
    modalIsActive.value = true
    screenManagerStore.setShowModal(true)
    pathName.value = route.name
    copyURLToClipBoard(url)
  }
  const copyURLToClipBoard = (url) => {
      navigator.clipboard.writeText(url);
  }
  const twitter = (url) => {
    let postUrl = 'https://x.com/share?'
    let minPrice = networkStore.getActiveNetwork.modifiedPrice === 0 ? networkStore.getActiveNetwork.price : networkStore.getActiveNetwork.modifiedPrice
    let price = currencyFormatter(minPrice, 3)
    let title = `Check out my $${price} $${networkStore.getActiveNetwork.ticker} price prediction this cycle, powered by #SimFasset 💥`
    title += networkStore.getActiveNetwork.ticker === 'SGB' ? `\n\n#Songbird #FlareNetwork #FAssets #Defi $SGB $FLR\n` :`\n\n#FlareNetwork #Songbird #FAssets #Defi $FLR $SGB\n`
    postUrl += `text=${encodeURIComponent(title)}`
    postUrl += `&url=${encodeURIComponent(url)}`
    window.open(postUrl, '_blank')
  }
  const createShareURL = () => {
    let url = `${window.location.origin}`
    //let url = 'http://localhost:8080' //21
    //let url = 'https://www.simfasset.xyz'
    url += `${route.path}?`
    url += networkStore.getQueryParams()
    url += coinStore.getQueryParams()
    url += configurationStore.getQueryParams(networkStore.getActiveNetwork.name)
    return url
  }

  //NEW - APP LOAD PERSISTED DATA
  const loadPersistedAppData = () => {
    const qpData = configurationStore.getLocalStorage(constant.LOCAL_STORAGE_KEY)
    
    if(qpData) {
      const persistedNetworkId = configurationStore.getLocalStorage(constant.LOCAL_STORAGE_NETWORK_KEY)
      networkStore.setActiveNetwork(networkStore.getNetworkNameById(persistedNetworkId).name)
      activeNetworkName.value = networkStore.getActiveNetwork.name

      //console.log('App - Init Load Persisted App Data')
      const index = networkStore.getActiveNetwork.id
      networkStore.setNetworksDefaults()
      configurationStore.setConfigsDefaults(activeNetworkName.value)

      //APP DATA SET - PERSISTED DATA
      if(qpData[index - 1].val) {
        const appData = configurationStore.getSerializedPresistedAppData(qpData[index - 1].val)
        //console.log('App Data - Persisted AppData: ', appData)
        //console.log('App Network - Set Active Network: ' + activeNetworkName.value + ' : ' + appData.n)
        networkStore.setQueryNetworkData(appData)
        //console.log('App Configuration - Set; Settings / Excludes')
        configurationStore.setQueryConfigsData(appData, activeNetworkName.value)
        //console.log('App CoinStore - Set Configurations')
        coinStore.setConfigurationSettings(configurationStore.getSystemConfigsByName(activeNetworkName.value))
        //console.log('App CoinStore - Set Coin Data')
        coinStore.setQueryCoinData(appData)
        //console.log('\n')
      }
    }
  }

  //NEW - CREATE PERSISTED DATA
  const getPersistedAppData = () => {
    let data = persistedDataObject()

    let properties = ''
    properties += networkStore.getQueryParams()
    properties += coinStore.getQueryParams()
    properties += configurationStore.getQueryParams(activeNetworkName.value)

    activeNetworkName.value === constant.FLARE_NETWORK ?
      data[1].val = properties :
      data[0].val = properties

    return data
  }

  const persistedDataObject = () => {
    const data = configurationStore.getLocalStorage(constant.LOCAL_STORAGE_KEY)
    return [
      {
        id: 1,
        val: data && data[0].val ? data[0].val : null
      },
      {
        id: 2,
        val: data && data[1].val ? data[1].val : null
      },
    ]
  }

  const trackEvent = (eventType, eventCategory, eventLabel) => {
    event(eventType, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }

  const showModalHelp = (event) => {
    helpSettingId.value = event.id
    modalIsActive.value = true
    isActiveHelp.value = true
    screenManagerStore.setShowModal(true)
  }

  const hideModal = (event) => {
    isActiveHelp.value = false
    isActiveSms.value = false
    isActiveMinCollateral.value = false
    modalIsActive.value = false
    screenManagerStore.setShowModal(false)
  }

  watch( route,(newValue, oldValue) => {
    const totalNetworkCoinCollateral = parseFloat(coinStore.getTotalCollateral) * networkStore.getNetworkCoinCollateralAllocationPercentage()

    if(newValue.name === constant.SIMULATE && totalNetworkCoinCollateral > 0 && totalNetworkCoinCollateral < 100000000 ) {
      modalIsActive.value = true
      isActiveMinCollateral.value = true
      screenManagerStore.setShowModal(true)
    }
  })

  const loadTimer = setTimeout(loadApp, 1500)
</script>
<style lang="scss">
  @import './assets/scss/style.scss';

  .mint-collateral-controls {
    top: 105px; //108px

    @include breakpoint(lg) {
      top: calc($HEADER-HEIGHT-DESKTOP - 2px);
    }
    @include breakpoint(xl) {
      top: calc($HEADER-HEIGHT-DESKTOP - 2px);
    }
    @include breakpoint(xxl) {
      top: calc($HEADER-HEIGHT-DESKTOP - 2px);
    }
    @include breakpoint(xxxl) {
      top: calc($HEADER-HEIGHT-DESKTOP - 2px);
    }
  }
</style>
<template>
  <input 
    type="range" 
    :min="min" 
    max="100" 
    v-model="sliderValue"
    :class="classes"
    :style="styles"
    :disabled="disabled"
    @blur="blur($event)"
    @mouseup="mouseup($event)"
    @mouseleave="mouseleave($event)"
  />
</template>
<script setup>
  import { ref, computed } from 'vue'
  
  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Object,
      default: ''
    },
    styles: {
      type: Object,
      default: () => {}
    },
    modelValue: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    }
  })

  const emit = defineEmits([
    'sliderValue',
    'blur',
    'mouseup',
    'mouseleave',
  ])
  
  const constant = props.constant

  const sliderValue = computed({
    get: () => {
      return props.modelValue
    },
    set: (value) => {
      emit('sliderValue', value)
    }
  })

  const blur = (event) => {
    emit('blur', event)
  }

  const mouseup = (event) => {
    emit('mouseup', event)
  }

  const mouseleave = (event) => {
    emit('mouseleave', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';
  
  .slider, .price-slider, .collateral-slider, .setting-slider {
    position: absolute;
    left: 0;
    right: 0;
    width: 90%;
    height: 13px;
    border-radius: 12px;
    margin: 20px auto 30px auto;
    border: none;

    @include breakpoint(lg) {
      width: 100%;
    }

    &:disabled {
      opacity: 1;
    }

    &.static-slider {
      &::-webkit-slider-thumb {
        width: 0;
        height: 0;
        border: none;
        background-color: transparent;
      }

      &::-moz-range-thumb {
        width: 0;
        height: 0;
        border: none;
        background-color: transparent;
      }
    }
  }

  .slider {
    margin: 30px auto 20px auto;

    @include breakpoint(lg) {
      width: 95%;
    }

    &.border-color-red {

      &::-webkit-slider-thumb {
        border: thin solid $RED-XLIGHT;
      }

      &::-moz-range-thumb {
        border: thin solid $RED-XLIGHT;
      }
    }
  }

  .slider-interactive {
    border: thin solid $PURPLE;

    &:disabled {
      border: thin solid $GREY;
    }
  }

  .price-slider, .collateral-slider, .setting-slider {
    height: 4px;
    width: 90%;

    &::-webkit-slider-thumb {
      width: 22px;
      height: 22px;
      border: none;
    }
  }

  .setting-slider {
    height: 3px;
    width: 80%;

    &::-webkit-slider-thumb {
      box-shadow: 0 2px 5px rgb(0 0 0 / 0.4);
    }
  }
</style>